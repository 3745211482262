import styled from '@emotion/styled';
import { Page, ModuleTile, ModuleTileInner } from '@zola/zola-ui/src/components/Grid';
import COLORS from '@zola/zola-ui/src/styles/emotion/colorsBaby';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

export const StyledPage = styled(Page)`
  margin-left: auto;
  margin-right: auto;
`;

export const GridTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;

  background-image: url('${process.env
    .ASSET_PATH}/static-baby/images/backgrounds/baby_wallpaper.svg');
  background-size: 580px;
  // background-position-y: -113%;
  background-repeat: repeat;
  height: 100%;
  background-color: ${COLORS.PD_ULTRAMARINE_100};

  padding: 24px;
  ${MEDIA_QUERY.MOBILE} {
    background-position: -45px;
  }
  ${MEDIA_QUERY.TABLET} {
    aspect-ratio: 1 / 1;
  }
  ${MEDIA_QUERY.DESKTOP} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  ${MEDIA_QUERY.DESKTOP_LG} {
    padding-top: 70px;
    padding-bottom: 70px;
  }
`;

export const ContentTile = styled(ModuleTile)`
  ${ModuleTileInner} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    row-gap: ${SPACING.S32};
    ${MEDIA_QUERY.DESKTOP} {
      padding: 89px;
    }
  }
`;

export const AppDownloadContainer = styled.div`
  background-color: ${COLORS.BLACK_005};
  border-radius: 16px;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 24px;
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }
`;

export const AppDownloadButton = styled(ButtonV3)`
  ${MEDIA_QUERY.DESKTOP} {
    display: none;
  }
`;

export const OnTheWayContainer = styled.div`
  display: flex;
  max-width: 800px;
  padding: 48px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  background-color: ${COLORS.WHITE_100};
  border-radius: 16px;
`;

export const DownloadText = styled.div`
  display: flex;
  flex-direction: column;
`;
