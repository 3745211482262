import { Grid } from '@zola/zola-ui/src/components/Grid';
import ButtonV3 from '@zola/zola-ui/src/components/ButtonV3/ButtonV3';
import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import T from '@zola/zola-ui/src/typography/Text';
import { SecondaryLink } from '~/components/zola-ui/LinkV2';
import BabyFooter from '@zola/zola-ui/src/components/footers/baby/BabyFooter';
import {
  StyledPage,
  GridTile,
  ContentTile,
  AppDownloadContainer,
  OnTheWayContainer,
  DownloadText,
  AppDownloadButton,
} from '~/components/ComingSoon/ComingSoon.styles';
import { BrandAppleIcon } from '@zola/zola-ui/src/components/SvgIconsV3/BrandApple';

import BabyQrCode from '~public/static-baby/images/qr/qr-baby-ios-coming-soon.svg';

const ComingSoon = () => {
  return (
    <StyledPage>
      <Grid>
        <GridTile>
          <OnTheWayContainer>
            <H.Title1 presentation="h2">Zola Baby on web is still on the way</H.Title1>
            <P.BodyBase>
              We’re working hard to bring Zola Baby to your desktop. In the meantime, return to the
              app to manage your registry. Don’t worry, your friends and family can view and shop
              your registry on web.
            </P.BodyBase>
            <AppDownloadContainer>
              <DownloadText>
                <P.BodyBase>
                  <T.Strong>Download Zola Baby</T.Strong>
                </P.BodyBase>
                <P.BodySmall color="BLACK_050">
                  <BrandAppleIcon height={24} width={24} css={{ verticalAlign: 'bottom' }} />
                  Available on iOS
                </P.BodySmall>
              </DownloadText>
              <img src={BabyQrCode} height="120" width={120} alt="Dowload the Zola Baby app" />
            </AppDownloadContainer>
            <AppDownloadButton
              component="a"
              href="https://apps.apple.com/app/apple-store/id6446598574?pt=31209800&ct=zola_web_coming_soon&mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download the app
            </AppDownloadButton>
            <P.BodyBase>
              Or{' '}
              <SecondaryLink href="/search/baby-registry" inline>
                find a baby registry
              </SecondaryLink>
            </P.BodyBase>
          </OnTheWayContainer>
        </GridTile>
        <ContentTile>
          <H.Title3 presentation="h2">Looking to create a wedding planning account?</H.Title3>
          <ButtonV3 component="a" href="https://www.zola.com/wedding/onboard/wedding-planning">
            Get started
          </ButtonV3>
        </ContentTile>
        <BabyFooter />
      </Grid>
    </StyledPage>
  );
};

export default ComingSoon;
