import styled from '@emotion/styled';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import COLORS from '@zola/zola-ui/src/styles/emotion/colorsBaby';

const PrimaryLinkStyles = `
  color: ${COLORS.PD_ULTRAMARINE_100};
  :hover {
    color: ${COLORS.PD_ULTRAMARINE_085};
  }
  :active,
  :focus {
    color: ${COLORS.PD_ULTRAMARINE_085};
  }
`;

export const PrimaryLink = styled(LinkV2)`
  ${PrimaryLinkStyles}
`;

const SecondaryLinkStyles = `
  color: ${COLORS.BLACK_100};
  :hover {
    color: ${COLORS.BLACK_075};
  }
  :active,
  :focus {
    color: ${COLORS.BLACK_075};
  }
`;

export const SecondaryLink = styled(LinkV2)`
  ${SecondaryLinkStyles}
`;
